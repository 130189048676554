import {NgModule}                       from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RippleModule}                   from "primeng/ripple";
import {ButtonModule}                   from "primeng/button";
import {StyleClassModule}               from "primeng/styleclass";
import {TagModule}                      from "primeng/tag";
import {MenuComponent}                  from "./static-components/menu/menu.component";
import {WysiwygComponent}               from "./content-components/wysiwyg/wysiwyg.component";
import {ListComponent}                  from "./content-components/list/list.component";
import {AssetComponent}                 from "./content-components/asset/asset.component";
import {FooterComponent}                from "./static-components/footer/footer.component";
import {BannerComponent}                from "./generic-components/banner/banner.component";
import {ContentComponent}               from "./content-components/content.component";
import {BlogGridComponent}              from "./generic-components/blog-grid/blog-grid.component";
import {StatisticsComponent}            from "./generic-components/statistics/statistics.component";
import {TilesComponent}                 from "./generic-components/tiles/tiles.component";
import {LogosComponent}                 from "./generic-components/logos/logos.component";
import {JobsComponent}                  from "./generic-components/jobs/jobs.component";
import {GridContentComponent}           from "./generic-components/grid-content/grid-content.component";
import {TestimonialsComponent}          from "./generic-components/testimonials/testimonials.component";
import {EmphasizedPostComponent}        from "./generic-components/emphasized-post/emphasized-post.component";
import {CallToActionComponent}          from "./generic-components/call-to-action/call-to-action.component";
import {SafeHTMLPipe}                   from "./pipes/SafeHTMLPipe";
import {IconComponent}                  from "./content-components/icon/icon.component";
import {GoogleMapComponent}             from "./generic-components/google-map/google-map.component";
import {GoogleMap, MapMarker}           from "@angular/google-maps";
import {LogoComponent}                  from "./static-components/logo/logo.component";
import {ButtonComponent}                from "./content-components/button/button.component";
import {SeaweedComponent}               from "./static-components/seaweed/seaweed.component";
import {EmptyComponent}                 from "./generic-components/empty/empty.component";
import {ReviewComponent}                from "./content-components/review/review.component";
import {NewsletterComponent}            from "./content-components/newsletter/newsletter.component";
import {TextComponent}                  from "./content-components/text/text.component";
import {TitleComponent}                 from "./generic-components/title/title.component";
import {BoxedComponent}                 from "./content-components/boxed/boxed.component";
import {FaqComponent}                   from "./generic-components/faq/faq.component";
import {AccordionModule}                from "primeng/accordion";
import {RelatedComponent}               from "./generic-components/related/related.component";
import {StepperComponent}               from "./generic-components/stepper/stepper.component";
import {VerticalTabsComponent}          from "./generic-components/vertical-tabs/vertical-tabs.component";
import {UspsComponent}                  from "./generic-components/usps/usps.component";
import {UspsBlockComponent}             from "./generic-components/usps-block/usps-block.component";
import {TeamComponent}                  from "./generic-components/team/team.component";
import {AvatarGroupModule}              from "primeng/avatargroup";
import {AvatarModule}                   from "primeng/avatar";
import {CategoryListComponent}          from "./generic-components/category-list/category-list.component";
import {HeroComponent}                  from "./generic-components/hero/hero.component";
import {BlockGeneratorComponent}        from "./static-components/block-generator/block-generator.component";
import {BlogGridItemComponent}          from "./generic-components/blog-grid/blog-grid-item/blog-grid-item.component";
import {TranslateModule}                from "@ngx-translate/core";
import {LocalizeRouterPipe}             from "@gilsdav/ngx-translate-router";
import {PricingTableComponent}          from "./generic-components/pricing-table/pricing-table.component";
import {
    PricingTableHeaderComponent
}                                       from "./generic-components/pricing-table/pricing-table-header/pricing-table-header.component";
import {JobSearchHeaderComponent}       from "./generic-components/job-search-header/job-search-header.component";
import {EmptySearchComponent}           from "./static-components/empty-search/empty-search.component";
import {PricingHeaderComponent}         from "./generic-components/pricing-header/pricing-header.component";
import {
    PricingHeaderColumnComponent
}                                       from "./generic-components/pricing-header/pricing-header-column/pricing-header-column.component";
import {FormsModule}                    from "@angular/forms";
import {InputTextModule}                from "primeng/inputtext";
import {CarouselModule}                 from "primeng/carousel";
import {
    SearchBoxHeaderComponent
}                                       from "./generic-components/job-search-header/search-box-header/search-box-header.component";
import {CookieDeclarationComponent}     from "./generic-components/cookie-declaration/cookie-declaration.component";
import {NgSelectModule}                 from "@ng-select/ng-select";
import {NgxChartsModule}                from "@swimlane/ngx-charts";
import {RouterLink}                     from "@angular/router";
import {ToolComponent}                  from "./generic-components/tool/tool.component";
import {BarChartComponent}              from "./generic-components/tool/chart/bar-chart.component";
import {BreadcrumbsComponent}           from "./generic-components/breadcrumbs/breadcrumbs.component";
import {FeaturedPersonComponent}        from "./generic-components/featured-person/featured-person.component";
import {StatisticsCtaComponent}         from "./generic-components/statistics-cta/statistics-cta.component";


const COMPONENTS = [
    FaqComponent,
    MenuComponent,
    LogoComponent,
    HeroComponent,
    IconComponent,
    TextComponent,
    TeamComponent,
    UspsComponent,
    ListComponent,
    JobsComponent,
    EmptyComponent,
    AssetComponent,
    ToolComponent,
    LogosComponent,
    TitleComponent,
    BoxedComponent,
    TilesComponent,
    ReviewComponent,
    ButtonComponent,
    FooterComponent,
    BannerComponent,
    StepperComponent,
    RelatedComponent,
    WysiwygComponent,
    SeaweedComponent,
    ContentComponent,
    BarChartComponent,
    BlogGridComponent,
    GoogleMapComponent,
    UspsBlockComponent,
    StatisticsComponent,
    NewsletterComponent,
    BreadcrumbsComponent,
    GridContentComponent,
    EmptySearchComponent,
    CallToActionComponent,
    CategoryListComponent,
    TestimonialsComponent,
    BlogGridItemComponent,
    VerticalTabsComponent,
    PricingTableComponent,
    StatisticsCtaComponent,
    PricingHeaderComponent,
    BlockGeneratorComponent,
    EmphasizedPostComponent,
    FeaturedPersonComponent,
    SearchBoxHeaderComponent,
    JobSearchHeaderComponent,
    CookieDeclarationComponent,
    PricingTableHeaderComponent,
    PricingHeaderColumnComponent,
]

const PIPES = [
    SafeHTMLPipe
]

@NgModule({
    declarations: [
        ...PIPES,
        ...COMPONENTS
    ],
    exports     : [
        ...PIPES,
        ...COMPONENTS
    ],
    imports     : [
        CommonModule,
        RippleModule,
        ButtonModule,
        AvatarModule,
        TranslateModule,
        AccordionModule,
        StyleClassModule,
        NgOptimizedImage,
        TagModule,
        GoogleMap,
        MapMarker,
        AvatarGroupModule,
        LocalizeRouterPipe,
        FormsModule,
        InputTextModule,
        CarouselModule,
        NgSelectModule,
        NgxChartsModule,
        RouterLink,
    ]
})
export class ComponentsModule {
}
