<div>
    <app-text [theme]="theme" [block]="block"></app-text>

    <div class="mt-4">
        <div class="flex align-items-center mb-4" *ngFor="let listItem of block.blocks">
            <i class="pi pi-check text-green-600 mr-3"></i>
            <div>
                <div [class]="'font-medium mb-1 ' + theme.current.textTitle">{{ listItem.title }}</div>
                <div [class]="'' + theme.current.textSubtitle">{{ listItem.subtitle }}</div>
            </div>
        </div>
    </div>
</div>
