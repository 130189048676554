<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">

    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="grid">
                <div *ngFor="let related of block.blocks" class="col-12 md:col-6 lg:col-4">
                    <a [href]="related.blocks[0] ? (related.blocks[0].entry ? related.blocks[0].entry : related.blocks[0].url) : '/'"
                       class="p-5 surface-card border-round relative overflow-hidden block no-underline h-full">
                        <app-seaweed alignment="right" height="280" width="280"></app-seaweed>

                        <div class="relative flex justify-content-between align-items-center">
                            <div class="flex align-items-center">
                                <app-icon [icon]="related.icon.value" class="mr-3 text-brand-color"></app-icon>
                                <div class="text-0 font-bold line-height-3">{{ related.title }}</div>
                            </div>

                            <div *ngIf="related.blocks[0] as button"
                                 class="w-2rem h-2rem flex align-items-center justify-content-center bg-brand-color rounded-full">
                                <app-icon [icon]="button.icon.value" class="text-900"></app-icon>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
