<div *ngIf="block.asset.mimeType === 'video/mp4'; else image" class="relative">
    <div (click)="toggleVideo()"
         [ngClass]="{'opacity-0 pointer-events-none': !video.paused && !mouseOver, 'opacity-100': mouseOver, 'pointer-events-none': mouseOver && (!video.paused && !video.ended)}"
         class="transition-opacity transition-duration-250 flex align-items-center justify-content-center absolute z-1 w-full h-full">
        <div class="w-16 h-16 bg-primary flex align-items-center justify-content-center rounded-full absolute z-1">
            <app-icon [icon]="video.paused || ended ? 'pi-play' : 'pi-pause'"></app-icon>
        </div>
    </div>
    <video class="block max-w-full h-auto relative rounded-2xl" #video [controls]="!video.paused && !video.ended" (ended)="ended = true" (play)="ended = false">
        <source [src]="block.asset.url" [type]="block.asset.mimeType">
    </video>
</div>

<ng-template #image>
    <picture>
        <source [srcset]="block.asset_webp?.url" [type]="block.asset_webp?.mimeType" />
        <source [srcset]="block.asset.url" [type]="block.asset.mimeType" />

            <img priority [height]="block.asset.height" [width]="block.asset.width" [ngSrc]="block.asset.url" alt="Image"
             [class]="'block max-w-full h-auto ' + blockClass">
    </picture>
</ng-template>
