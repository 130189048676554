<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="grid">
                <div *ngFor="let job of block.blocks" class="col-12 lg:col-6 xl:col-4 cursor-pointer"
                     (click)="navigateJob(job.meta.id)">
                    <div class="bg-white-alpha-60 shadow-2 p-3 h-full flex" style="border-radius: 10px">
                        <div [style]="'background-image:url(data:image/jpeg;base64,'+ job.asset.url +')'"
                             class="mr-3 h-[42px] w-[42px] bg-contain flex-shrink-0 bg-no-repeat bg-center">
                        </div>
                        <div>
                            <p class="mt-0 mb-0 line-height-2 text-900 font-medium">{{ job.title }}</p>
                            <p class="mt-0 mb-0 line-height-2 text-900">{{ job.subtitle }}</p>
                            <p class="mt-0 mb-0 line-height-2 text-600">{{ job.text }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div [class]="'text-center ' + theme.current.content">
                <a [class]="'no-underline ' + theme.current.content" [href]="block.url ? block.url : block.entry">
                    {{ "FIND_ALL_ASSIGNMENTS" | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
