<ng-container *ngIf="page">
    <ng-container *ngFor="let block of page.blocks">
        <ng-container [ngSwitch]="block.type">
            <app-faq
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'faq'"></app-faq>

            <app-usps
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'usps'"></app-usps>

            <app-jobs
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'jobComponent'"></app-jobs>

            <app-team
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'teamsComponent'"></app-team>

            <app-hero
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'heroComponent'"></app-hero>

            <app-tool
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'benchmark'"></app-tool>

            <app-empty
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'emptyComponent'"></app-empty>

            <app-logos
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'logos'"></app-logos>

            <app-tiles
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'tiles'"></app-tiles>

            <app-title
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'titleComponent'"></app-title>

            <app-banner
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'banner'"></app-banner>

            <app-stepper
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'stepper'"></app-stepper>

            <app-related
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'related'"></app-related>

            <app-blog-grid
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'blogGrid'"></app-blog-grid>

            <app-statistics
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'statistics'"></app-statistics>

            <app-usps-block
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'uspsBlock'"></app-usps-block>

            <app-google-map
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'map'"></app-google-map>

            <app-grid-content
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'gridContentComponent'"></app-grid-content>

            <app-testimonials
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'testimonials'"></app-testimonials>

            <app-featured-person
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'featuredPerson'"></app-featured-person>

            <app-statistics-cta
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'statisticsCta'"></app-statistics-cta>

            <app-vertical-tabs
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'verticalTabs'"></app-vertical-tabs>

            <app-category-list
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'categoryList'"></app-category-list>

            <app-call-to-action
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'callToAction'"></app-call-to-action>

            <app-emphasized-post
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'emphasizedPost'"></app-emphasized-post>

            <app-job-search-header
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'jobSearchHeader'"></app-job-search-header>

            <app-pricing-table
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'pricingComponent'"></app-pricing-table>

            <app-pricing-header
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'pricingHeaderComponent'"></app-pricing-header>

            <app-cookie-declaration
                    [block]="block"
                    [container]="container"
                    *ngSwitchCase="'cookieDeclaration'"></app-cookie-declaration>
        </ng-container>
    </ng-container>
</ng-container>
