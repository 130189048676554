import {BlockComponentModel}      from "./block";
import {ContentType, ThemeOption} from "./generic";
import {InjectionToken}           from "@angular/core";

export interface Environment {
    apiUrl: string;
    production: boolean;
}

export interface Ancestor {
    title: string;
    url: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('Environment');

export const OFFSET: { [value: string]: { top: string, left: string, bottom: string, right: string } } = {
    '32' : {
        top   : 'mb-7 -mt-2 md:!-mt-8',
        left  : 'my-7 -ml-2 md:!-ml-8',
        bottom: 'mt-7 -mb-2 md:!-mb-8',
        right : 'my-7 -mr-2 md:!-mr-8'
    },
    '64' : {
        top   : 'mb-7 -mt-4 md:!-mt-16',
        left  : 'my-7 -ml-4 md:!-ml-16',
        bottom: 'mt-7 -mb-4 md:!-mb-16',
        right : 'my-7 -mr-4 md:!-mr-16'
    },
    '128': {
        top   : 'mb-7 -mt-8 md:!-mt-32',
        left  : 'my-7 -ml-8 md:!-ml-32',
        bottom: 'mt-7 -mb-8 md:!-mb-32',
        right : 'my-7 -mr-8 md:!-mr-32'
    },
    '160': {
        top   : 'mb-7 -mt-12 md:!-mt-28 lg:!-mt-40',
        left  : 'my-7 -ml-12 md:!-mt-28 lg:!-mt-40',
        bottom: 'mt-7 -mb-12 md:!-mt-28 lg:!-mt-40',
        right : 'my-7 -mr-12 md:!-mt-28 lg:!-mt-40'
    },
    '192': {
        top   : 'mb-7 -mt-16 md:!-mt-48',
        left  : 'my-7 -ml-16 md:!-ml-48',
        bottom: 'mt-7 -mb-16 md:!-mb-48',
        right : 'my-7 -mr-16 md:!-mr-48'
    },
    '224': {
        top   : 'mb-7 -mt-20 md:!-mt-56',
        left  : 'my-7 -ml-20 md:!-ml-56',
        bottom: 'mt-7 -mb-20 md:!-mb-56',
        right : 'my-7 -mr-20 md:!-mr-56'
    }
}

export const ALIGNMENT: { [value: string]: { odd: string, even: string } } = {
    '4-8'    : {
        even: 'col-12 md:col-4 text-left',
        odd : 'col-12 md:col-8 text-left',
    },
    '8-4'    : {
        even: 'col-12 md:col-8 text-left',
        odd : 'col-12 md:col-4 text-left',
    },
    '6-6'    : {
        even: 'col-12 md:col-6 text-left',
        odd : 'col-12 md:col-6 text-left',
    },
    '4-4-4'  : {
        even: 'col-12 md:col-4 text-left',
        odd : 'col-12 md:col-4 text-left',
    },
    '3-3-3-3': {
        even: 'col-12 md:col-6 lg:col-3',
        odd : 'col-12 md:col-6 lg:col-3',
    },
    '6'      : {
        even: 'col-12 md:col-6 text-left',
        odd : 'col-12 md:col-6 text-left',
    },
    '12'     : {
        even: 'col-12 text-left',
        odd : 'col-12 text-left',
    },
}

export const GRID_ALIGNMENT: { [value: string]: string } = {
    left  : 'justify-content-start',
    right : 'justify-content-end',
    center: 'justify-content-center',
}

export const TITLE_ALIGNMENT: { [value: string]: string } = {
    left  : 'text-left',
    right : 'text-right',
    center: 'text-left md:text-center',
}

export const BG_VALUES: { [value: string]: string } = {
    'left'  : 'mr-auto',
    'right' : 'ml-auto',
    'center': 'mx-auto',
}

export const THEMES: { [value: string]: ThemeOption } = {
    dark : {
        card                : 'surface-card',
        boxed               : 'bg-primary-800',
        button              : '',
        content             : 'text-white [&>p]:text-white [&>h2]:text-white [&>h3]:text-white [&>h4]:text-white [&>h5]:text-white [&>h5]:text-white [&>h6]:text-white',
        textTitle           : 'text-white',
        newsletter          : 'bg-orange-500',
        breadcrumbs         : 'bg-brand-color-3',
        textSubtitle        : 'text-white',
        textSubtitleContrast: 'text-primary-lighter',
    },
    light: {
        card                : '',
        boxed               : 'bg-orange-200',
        button              : '',
        content             : 'text-900 [&>p]:text-900 [&>h2]:text-900 [&>h3]:text-900 [&>h4]:text-900 [&>h5]:text-900 [&>h6]:text-600',
        textTitle           : 'text-900',
        newsletter          : 'bg-white-alpha-40',
        breadcrumbs         : 'bg-orange-500',
        textSubtitle        : 'text-600',
        textSubtitleContrast: 'text-primary-darker',
    }
}

export class Theme {
    current!: ThemeOption;
    fullTheme     = '';
    fullTextTheme = '';

    constructor(public theme: string) {
        this.current       = THEMES[theme];
        this.fullTheme     = [...new Set(Object.values(this.current))].join(' ')
            .replace('bg-primary-800', '')
            .replace('bg-orange-200', '')
            .replace('bg-orange-500', '')
            .replace('bg-brand-color-3', '')
            .replace('bg-white-alpha-40', '');
        this.fullTextTheme = this.fullTheme.replace('card', '')
    }
}

function isContent(c: { type: string }) {
    return c.type === ContentType.Text
        || c.type === ContentType.List
        || c.type === ContentType.Asset
        || c.type === ContentType.Boxed
        || c.type === ContentType.Review
        || c.type === ContentType.Limited
        || c.type === ContentType.Newsletter
}

export function returnContent(block: {
    blocks: BlockComponentModel[]
}, returnArray = false, exactType?: string): BlockComponentModel | BlockComponentModel[] | undefined {
    if (!block) {
        return undefined;
    }

    let r = block.blocks
        .filter(c => isContent(c))
        .map(c => c);

    if (exactType) {
        r = r
            .filter(c => c.type === exactType);
    }

    return (!returnArray ? (r.length ? (r[0] as BlockComponentModel) : undefined) : (r as BlockComponentModel[]));
}

export function returnComponents(block: {
    blocks: BlockComponentModel[]
}, returnArray = false, exactType?: string): BlockComponentModel | BlockComponentModel[] | undefined {
    if (!block) {
        return undefined;
    }

    let r = block.blocks
        .filter(c => !isContent(c))
        .map(c => c);

    if (exactType) {
        r = r
            .filter(c => c.type === exactType)
    }

    return (!returnArray ? (r.length ? (r[0] as BlockComponentModel) : undefined) : (r as BlockComponentModel[]));
}
