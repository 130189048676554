<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">

            <div class="max-w-[600px] mx-auto [&>img]:mx-auto">
                <app-asset *ngIf="block.asset" [block]="block" [theme]="theme"
                           blockClass=""></app-asset>
            </div>

            <div class="bg-white-alpha-40 p-5 rounded-[28px]" id="benchmarkTool">
                <app-text [block]="block" [theme]="theme"></app-text>

                <div class="text-700 mb-3 flex flex-column md:flex-row">
                    <span class="text-700 mb-2 md:mb-0">{{ 'BASED_PROFILES' | translate }}</span>
                    <span class="text-300 mx-4 hidden md:block">|</span>
                    <span class="text-700 mb-2 md:mb-0">{{ 'UNIQUE_MARKET' | translate }}</span>
                    <span class="text-300 mx-4 hidden md:block">|</span>
                    <span class="text-700 mb-2 md:mb-0">{{ 'FREE_AND_ANONYMOUS' | translate }}</span>
                    <span class="text-300 mx-4 hidden md:block">|</span>
                    <span class="text-700 mb-2 md:mb-0">{{ '1_MINUTE' | translate }}</span>
                </div>

                <div class="flex-col flex mb-9 md:flex-row">
                    <div class="grow mb-3 md:mb-0 relative mr-3">
                        <ng-select
                                [(ngModel)]="ngModel"
                                [items]="completions$ | async"
                                [placeholder]="'SEARCH_FUNCTION' | translate"
                                [searchable]="true"
                                [typeToSearchText]="'START_YOUR_SEARCH' | translate"
                                [typeahead]="typeahead$"
                        ></ng-select>

                        <div class="absolute top-[16px] left-[10.5px] z-[9999]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-[24px] h-[24px]">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                    </div>

                    <div class="block md:flex shrink-0">
                        <button pButton pRipple
                                (click)="getResults(ngModel)" [disabled]="!ngModel"
                                class="font-bold rounded-full block md:inline-block text-center mr-3 w-full mb-3 md:mb-0 md:w-auto">
                            {{ 'ANALYZE_OFFER' | translate }}
                        </button>
                        <a pButton pRipple
                                [routerLink]="['/opdrachten' | localize]"
                                class="font-bold flex align-items-center rounded-full p-button-icon-right p-button-outlined w-full md:w-auto no-underline">
                            {{ 'FIND_ASSIGNMENTS' | translate }}
                        </a>
                    </div>
                </div>

                <div class="bg-white p-4 rounded-[11px] shadow-1">
                    <p *ngIf="query" class="mb-5 text-left">{{ 'FUNCTION' | translate }} <b
                            class="text-primary">{{ query }}</b> {{ 'ON_STRIIVE_COM' | translate }}</p>

                    <div class="flex-col flex md:flex-row">
                        <div class="shrink-0 md:mt-16 md:mr-6">
                            <div class="py-2 bg-white rounded-[6px] mb-2.5 text-gray-dark text-left"><strong
                                    class="text-gray-darker">{{ professionalLength !== null ? professionalLength : '...' }}</strong>
                                professionals
                            </div>
                            <div class="py-2 bg-white rounded-[6px] mb-2.5 md:mb-0 text-gray-dark text-left"><strong
                                    class="text-gray-darker">€{{ averageCost !== null ? averageCost : '...' }}</strong>
                                / {{ 'HOURLY_RATE' | translate }}
                            </div>
                        </div>

                        <div
                                class="relative grow overflow-hidden bg-white rounded-[6px] md:bg-transparent md:rounded-none"
                                #containerRef
                        >
                            <div class="absolute text-blue-info right-[24px] top-[10px] z-10 group">
                                <div class="absolute -top-[10px] opacity-0 group-hover:opacity-100 ease-in-out transition-all bg-gray-900 py-1.5 px-3 text-white right-[34px] w-[230px] text-center rounded-[6px] pointer-events-none group-hover:pointer-events-auto">
                                    {{ 'SIMILAR_FUNCTIONS' | translate }}
                                    <div class="h-0 w-0 border-y-8 border-y-transparent border-l-[11px] border-l-gray-900 absolute -right-[11px] top-[15px]"></div>
                                </div>
                            </div>

                            <div class="relative max-h-[150px] pt-[40px] md:pt-[20px] bg-white rounded-[6px] pointer-events-none">
                                <app-bar-chart
                                        (window:resize)="resizeChart(containerRef.offsetWidth)"
                                        [barPadding]="2"
                                        [results]="results"
                                        [roundEdges]="false"
                                        [scheme]="colorScheme"
                                        [tooltipDisabled]="true"
                                        [view]="view"
                                >
                                </app-bar-chart>
                                <div class="absolute top-[14px] bottom-[14px] left-[24px] right-[24px]">
                                    <div [style]="'left: calc(' + averageBarLeft + '% - ' + ((barWidth / 2) + 4.5) + 'px);'"
                                         class="absolute w-[5px] bottom-0 bg-green-primary ease-in-out transition-all duration-500 top-[15px] md:top-0">

                                        <div
                                                class="w-[10px] h-[10px] bg-green-primary -left-[2.5px] rotate-45 absolute bottom-0 z-10"></div>

                                        <div class="text-sm text-gray-info w-max absolute left-[10px] md:left-[20px]">
                                            {{ 'AVERAGE_RATE' | translate }}
                                        </div>
                                        <div *ngIf="averageCost"
                                             class="text-xs text-green-primary font-bold absolute -bottom-[47px] left-1/2 -translate-x-1/2">
                                            €{{ averageCost }}
                                        </div>
                                    </div>

                                </div>
                                <div class="absolute left-0 right-0 bottom-0 h-[15px] bg-white"></div>
                            </div>

                            <div class="flex justify-between px-6 py-3.5">
                                <p class="text-xs text-gray-darker">{{ minCost ? ('€' + minCost) : '...' }}</p>
                                <p class="text-xs text-gray-darker">{{ maxCost ? ('€' + maxCost) : '...' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
