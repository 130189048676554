<ng-container *ngIf="page">
    <app-menu *ngIf="header" [menu]="header.blocks" [seo]="page.seo"></app-menu>
    <app-banner *ngIf="banner" [block]="banner"></app-banner>

    <p-sidebar [(visible)]="mobileFilterOn" position="right">
        <div class="mt-3 relative">
            <p-multiSelect [options]="hoursPerWeek" [placeholder]="'HOURS_PER_WEEK' | translate"
                           optionLabel="name"
                           optionValue="value"
                           [(ngModel)]="selectedHour"
                           (ngModelChange)="filter()"
                           [selectionLimit]="1"
                           display="chip"
                           styleClass="w-full text-sm p-2.5 mb-3" [maxSelectedLabels]="1"
                           emptyMessage="{{'NO_RESULTS' | translate }}"
                           class="text-900 w-full"
                           [panelStyle]="{'max-width':'280px'}">
            </p-multiSelect>

            <p-multiSelect [options]="clients" placeholder="{{'CLIENT' | translate}}" optionLabel="name"
                           optionValue="value"
                           [(ngModel)]="selectedClient"
                           (ngModelChange)="filter()"
                           [selectionLimit]="1"
                           display="chip"
                           styleClass="w-full text-sm p-2.5 mb-3" [maxSelectedLabels]="1"
                           emptyMessage="{{'NO_RESULTS' | translate }}"
                           class="text-900 w-full"
                           [panelStyle]="{'max-width':'280px'}">
            </p-multiSelect>

            <p-multiSelect [options]="locations" placeholder="{{'LOCATION' | translate}}" optionLabel="name"
                           optionValue="value"
                           [(ngModel)]="selectedLocation"
                           (ngModelChange)="filter()"
                           [selectionLimit]="1"
                           display="chip"
                           styleClass="w-full text-sm p-2.5 max-w-[100%]" [maxSelectedLabels]="1"
                           emptyMessage="{{'NO_RESULTS' | translate }}"
                           class="text-900 w-full"
                           [panelStyle]="{'max-width':'280px'}">
            </p-multiSelect>
        </div>
    </p-sidebar>

    <div>
        <div class="bg-brand-color-3 py-3 text-white hidden md:block">
            <div class="lg:container m-auto flex justify-content-between text-white align-items-center px-3 xl:!px-42">
                <h1 class="m-0 text-2xl" [innerHTML]="page.title | safeHTML"></h1>

                <div class="flex align-items-center">
                    <a class="mr-3 text-white no-underline" target="_blank"
                       href="https://support.striive.com/nl">{{ "HOW_DOES_STRIIVE_WORK" | translate }}</a>
                    <button
                            (click)="redirectLogin(); createAccountClick()"
                            pButton pRipple class="font-bold text-white rounded-3xl p-button-outlined border-primary">
                        {{ "CREATE_ACCOUNT" | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="py-3 relative bg-white border-bottom-1 surface-border">
            <div class="overflow-hidden relative w-full h-full">
                <app-seaweed alignment="left" [theme]="theme"></app-seaweed>
            </div>

            <div class="lg:container m-auto relative z-2 flex px-3 xl:!px-42 flex-wrap lg:flex-nowrap">
                <form class="flex mb-3 lg:mb-0 lg:mr-3 w-full lg:w-auto align-items-center" (ngSubmit)="filter()">
                    <input [placeholder]="'SEARCH_PLACEHOLDER' | translate" type="text" pInputText
                           [(ngModel)]="query"
                           [ngModelOptions]="{standalone: true}"
                           class="py-2.5 px-2.5 text-base rounded-bl-3xl rounded-tl-3xl rounded-tr-none rounded-br-none w-full">
                    <button pButton pRipple
                            type="submit"
                            class="font-bold no-underline rounded-tr-3xl rounded-br-3xl rounded-bl-none rounded-tl-none px-5">
                        <app-icon icon="pi-search" class=""></app-icon>
                    </button>

                    <div>
                        <app-icon icon="pi-sliders-h" (click)="mobileFilterOn = true"
                                  class="shrink-0 ml-3 mr-3 text-xl block md:hidden"></app-icon>
                    </div>
                </form>

                <div class="flex align-items-center justify-content-between flex-1">
                    <div (click)="redirectLogin()"
                         class="cursor-pointer flex justify-content-center w-full md:w-auto md:justify-content-start border-top-1 surface-border md:border-none mt-3 md:mt-0 pt-3 md:pt-0">
                        <div class="flex align-items-center pointer-events-none">
                            <app-icon icon="pi-bell" class="text-primary text-xl mr-2.5"></app-icon>
                            <p class="m-0 text-primary">{{ "NOTIFY_ME" | translate }}</p>
                            <p-inputSwitch class="flex ml-2.5"></p-inputSwitch>
                        </div>
                    </div>

                    <div class="shrink-0 hidden md:flex">
                        <p-multiSelect [options]="hoursPerWeek" [placeholder]="'HOURS_PER_WEEK' | translate"
                                       optionLabel="name"
                                       optionValue="value"
                                       [(ngModel)]="selectedHour"
                                       (ngModelChange)="filter()"
                                       [selectionLimit]="1"
                                       display="chip"
                                       styleClass="w-full max-w-[200px] text-sm" [maxSelectedLabels]="1"
                                       emptyMessage="{{'NO_RESULTS' | translate }}"
                                       class="flex-auto lg:flex-1 w-full text-900 mr-3 border-none [&>div]:border-none"
                                       [panelStyle]="{'width':'300px'}"></p-multiSelect>

                        <p-multiSelect [options]="clients" placeholder="{{'CLIENT' | translate}}" optionLabel="name"
                                       optionValue="value"
                                       [(ngModel)]="selectedClient"
                                       (ngModelChange)="filter()"
                                       [selectionLimit]="1"
                                       display="chip"
                                       styleClass="w-full max-w-[200px] text-sm" [maxSelectedLabels]="1"
                                       emptyMessage="{{'NO_RESULTS' | translate }}"
                                       class="flex-auto lg:flex-1 w-full text-900 mr-3 border-none [&>div]:border-none"
                                       [panelStyle]="{'width':'300px'}"></p-multiSelect>

                        <p-multiSelect [options]="locations" placeholder="{{'LOCATION' | translate}}" optionLabel="name"
                                       optionValue="value"
                                       [(ngModel)]="selectedLocation"
                                       (ngModelChange)="filter()"
                                       [selectionLimit]="1"
                                       display="chip"
                                       styleClass="w-full max-w-[200px] text-sm" [maxSelectedLabels]="1"
                                       emptyMessage="{{'NO_RESULTS' | translate }}"
                                       class="flex-auto lg:flex-1 w-full text-900 border-none [&>div]:border-none"
                                       [panelStyle]="{'width':'300px'}"></p-multiSelect>
                    </div>
                </div>
            </div>
        </div>


        <div class="bg-white">
            <div class="lg:container m-auto relative z-1 flex justify-content-between xl:!px-42">
                <div class="flex w-full">

                    <div class="w-full md:!w-[285px] lg:!w-[365px] grow-0 shrink-0 py-3.5 md:border-right-1 surface-border"
                         [ngClass]="{'hidden md:block': currentJob !== null}">
                        <div class="flex px-3">
                            <button pButton pRipple
                                    class="font-bold rounded-tl-2xl rounded-bl-2xl rounded-tr-none rounded-br-none flex-1 text-sm px-0 flex justify-content-center align-items-center">
                                {{ "ALL" | translate }} ({{ jobResponse ? jobResponse.total : '0' }})
                            </button>
                            <button pButton pRipple
                                    (click)="redirectLogin(); addFavorite();"
                                    class="font-bold rounded-none flex-1 p-button-outlined border-right-none border-left-none text-sm px-0 flex justify-content-center align-items-center p-button-secondary">
                                <app-icon icon="pi-star" class="mr-1"></app-icon>
                                {{ "FAVORITE" | translate }}
                            </button>
                            <button pButton pRipple
                                    (click)="redirectLogin()"
                                    class="font-bold rounded-br-2xl rounded-tr-2xl rounded-bl-none rounded-tl-none flex-1 p-button-outlined text-sm px-0 flex justify-content-center align-items-center p-button-secondary">
                                <app-icon icon="pi-bolt" class="mr-1"></app-icon>
                                {{ "RECOMMENDED" | translate }}
                            </button>
                        </div>

                        <div *ngIf="jobResponse as jobs" class="mt-3">
                            <div *ngFor="let job of jobs.data; let last = last"
                                 (click)="viewJob(job.id)"
                                 class="bg-white flex hover:bg-bluegray-50 cursor-pointer py-2.5 px-3 relative"
                                 [ngClass]="{'bg-green-50': currentJob && currentJob.id === job.id, 'bg-white': !currentJob}">

                                <div *ngIf="currentJob && currentJob.id === job.id"
                                     class="absolute -right-2 border-solid border-l-green-50 border-l-8 border-y-transparent border-y-8 border-r-0 -mt-2 top-1/2 transform -translate-y-1/2"></div>

                                <div [style]="'background-image:url(data:image/jpeg;base64,'+ job.clientLogo +')'"
                                     class="mr-3 h-[42px] w-[42px] bg-contain border-1 surface-border rounded flex-shrink-0 bg-no-repeat bg-center">
                                </div>

                                <div>
                                    <div class="mt-0 mb-1 table table-fixed w-full">
                                        <p class="m-0 line-height-2 text-900 font-bold truncate">{{ job.title }}</p>
                                    </div>
                                    <div class="mt-0 mb-1 table table-fixed w-full">
                                        <p class="mt-0 mb-1 line-height-2 text-900 truncate">{{ job.clientName }}</p>
                                    </div>
                                    <div class="mt-0 mb-1 table table-fixed w-full">
                                        <p class="mt-0 mb-0 line-height-2 text-600 truncate">{{ job.location }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="currentJob; else emptySearch" class="overflow-hidden">
                        <div class="border-bottom-1 surface-border py-3 px-5 flex justify-content-between">
                            <div class="flex">
                                <div class="block md:hidden pr-3 pt-3" (click)="clearCurrentJob()">
                                    <i class="pi pi-chevron-left text-700"></i>
                                </div>
                                <div>
                                    <p class="mb-1 mt-0 text-500">{{ currentJob.clientName }}</p>
                                    <h1 class="text-2xl text-900 m-0">{{ currentJob.title }}</h1>
                                </div>
                            </div>

                            <div class="flex">
                                <div>
                                    <button pButton pRipple
                                            (click)="redirectLogin()"
                                            class="font-bold p-button-icon-only p-button-text mr-2">
                                        <app-icon icon="pi-star"></app-icon>
                                    </button>
                                </div>

                                <div class="hidden md:block">
                                    <button
                                            (click)="redirectLogin(); jobApply()"
                                            pButton pRipple class="font-bold mb-2 rounded-3xl">
                                        {{ "RESPOND" | translate }}
                                    </button>
                                    <p class="text-400 m-0 text-right">{{ (currentJob.referenceCode ? currentJob.referenceCode : currentJob.referenceCodeClient) }}</p>
                                </div>
                            </div>
                        </div>


                        <div class="py-3 px-5">
                            <p-messages [(value)]="messages" [closable]="true" styleClass="mt-0 mb-4">
                            </p-messages>

                            <div class="flex text-xs flex-wrap md:flex-nowrap mb-4 md:mb-0">
                                <div class="flex mb-2 md:mb-0 px-4 md:py-2.5 md:mr-2.5">
                                    <div class="flex flex-column justify-content-between mr-2">
                                        <div class="flex-1 flex align-items-center mb-2 text-500">
                                            <i class="pi pi-clock mr-2 text-xl"></i>
                                            {{ "HOURS_PER_WEEK" | translate }}
                                        </div>
                                        <div class="flex-1 flex align-items-center text-500">
                                            <i class="pi pi-map-marker mr-2 text-xl"></i>
                                            {{ "LOCATION" | translate }}
                                        </div>
                                    </div>

                                    <div>
                                        <div class="mb-2 text-900 leading-5">
                                            <span class="">{{ currentJob.hoursPerWeekMax }}</span>
                                        </div>

                                        <div class="text-900 leading-5">
                                            <span class="">{{ currentJob.location }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex px-4 md:py-2.5">
                                    <div class="flex flex-column justify-content-between mr-2">
                                        <div class="flex-1 flex align-items-center mb-2 text-500">
                                            <i class="pi pi-calendar mr-2 text-xl"></i>
                                            {{ "START_ASSIGNMENT" | translate }}
                                        </div>
                                        <div class="flex-1 flex align-items-center text-500">
                                            <i class="pi pi-lock mr-2 text-xl"></i>
                                            {{ "RESPOND_UNTILL" | translate }}
                                        </div>
                                    </div>

                                    <div>
                                        <div class="mb-2 text-900 leading-5">
                                            <span class="">{{ currentJob.startDate | date:"dd-MM-yyyy" }}</span>
                                        </div>

                                        <div class="text-900 leading-5">
                                            <span class="">{{ currentJob.closingDateClient | date:"dd-MM-yyyy" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-4 surface-100 py-2.5 px-4 rounded text-xs relative">
                                <div class="absolute w-full h-full flex align-items-center justify-content-center top-0">
                                    <button pButton pRipple
                                            (click)="redirectLogin()"
                                            class="font-bold p-button-text p-button-secondary text-sm">
                                        {{ "DEMANDS_AND_WISHES" | translate }}
                                    </button>
                                </div>
                                <div class="flex mr-2.5 pointer-events-none">
                                    <div class="flex flex-column justify-content-between mr-5">
                                        <div class="flex-1 flex align-items-center text-500 py-2.5">
                                            <i class="pi pi-check-circle mr-2 text-xl"></i>
                                            {{ "DEMANDS" | translate }}
                                            <i class="pi pi-info-circle text-blue-500 ml-2"></i>
                                        </div>
                                        <div class="flex-1 flex align-items-center text-500 py-2.5">
                                            <i class="pi pi-list mr-2 text-xl"></i>
                                            {{ "WISHES" | translate }}
                                            <i class="pi pi-info-circle text-blue-500 ml-2"></i>
                                        </div>
                                    </div>

                                    <div style="filter: blur(8px);" class="truncate">
                                        <div class="py-2.5 text-900 leading-5">
                                            <span class="mr-2">Lorem</span>
                                            <span class="mr-2">Ipsum</span>
                                            <span class="mr-2">Dolor</span>
                                            <span class="mr-2">Sit</span>
                                            <span class="mr-2">Amet</span>
                                            <span class="mr-2">consectetur</span>
                                            <span class="mr-2">adipiscing</span>
                                            <span class="mr-2">elit</span>
                                        </div>

                                        <div class="py-2.5 text-900 leading-5">
                                            <span class="mr-2">elit</span>
                                            <span class="mr-2">adipiscing</span>
                                            <span class="mr-2">consectetur</span>
                                            <span class="mr-2">Amet</span>
                                            <span class="mr-2">Sit</span>
                                            <span class="mr-2">Dolor</span>
                                            <span class="mr-2">Ipsum</span>
                                            <span class="mr-2">Lorem</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="w-full flex align-items-center px-4 py-2.5 my-4 surface-100 rounded flex-wrap md:flex-nowrap">
                                <div class="mr-2.5">
                                    <p-avatar [image]="'data:image/jpeg;base64,'+ currentJob.recruiterImage +''"
                                              shape="circle"
                                              size="large"></p-avatar>
                                </div>
                                <div class="md:mr-4 basis-3/4 md:basis-0">
                                    <p class="m-0 text-900 text-sm">{{ currentJob.recruiterFirstName }}</p>
                                    <p class="m-0 text-700 text-sm">Recruiter Striive</p>
                                </div>

                                <div class="cursor-pointer text-primary px-3.5 font-bold flex align-items-center mt-3 md:mt-0"
                                     (click)="redirectLogin()">
                                    <app-icon icon="pi-envelope"></app-icon>
                                </div>

                                <div class="flex cursor-pointer text-primary px-3.5 font-bold align-items-center mt-3 md:mt-0"
                                     (click)="redirectLogin()">
                                    <app-icon icon="pi-phone"></app-icon>
                                </div>
                            </div>

                            <div [innerHTML]="currentJob.content | safeHTML"
                                 class="text-700 text-sm [*>b]:text-brand-color break-all"></div>

                            <p-accordion [activeIndex]="0" styleClass="text-sm">
                                <p-accordionTab header="{{'CONDITIONS' | translate}}">
                                    <p>{{ "LIABILITY_INSURANCE" | translate }}</p>
                                </p-accordionTab>
                            </p-accordion>

                            <div class="my-3 py-4">
                                <h3 class="mt-0 mb-3 text-800 text-xl">{{ "SUITABLE_CANDIDATE" | translate }}</h3>
                                <div class="flex">
                                    <button pButton pRipple
                                            (click)="redirectLogin(); jobApply()"
                                            class="font-bold rounded-3xl flex-1 block text-center mr-3">
                                        {{ "RESPOND_NOW" | translate }}
                                    </button>

                                    <a pButton pRipple
                                       target="_blank"
                                       href="https://striive.com/nl/professionals/benchmark"
                                       class="font-bold no-underline rounded-3xl flex-1 p-button-icon-right p-button-outlined flex align-items-center justify-content-center">
                                        {{ "CALCULATE_RATE" | translate }}
                                        <app-icon icon="pi-external-link" class="ml-2"></app-icon>
                                    </a>
                                </div>
                            </div>

                            <p-accordion [activeIndex]="0" styleClass="text-sm">
                                <p-accordionTab header="{{'ASSIGNMENT_DETAILS' | translate}}">
                                    <div class="border-bottom-1 surface-border">
                                        <p class="text-900 mb-0">{{ "CLIENT" | translate }}</p>
                                        <p class="text-600 mt-0">{{ currentJob.clientName }}</p>
                                    </div>
                                    <div class="border-bottom-1 surface-border">
                                        <p class="text-900 mb-0">{{ "START_DATE" | translate }}</p>
                                        <p class="text-600 mt-0">{{ currentJob.startDate | date:"dd-MM-yyyy" }}</p>
                                    </div>
                                    <div class="border-bottom-1 surface-border">
                                        <p class="text-900 mb-0">{{ "END_DATE" | translate }}</p>
                                        <p class="text-600 mt-0">{{ currentJob.endDate | date:"dd-MM-yyyy" }}</p>
                                    </div>
                                    <div class="border-bottom-1 surface-border">
                                        <p class="text-900 mb-0">{{ "RESPOND_UNTILL" | translate }}</p>
                                        <p class="text-600 mt-0">{{ currentJob.closingDateClient | date:"dd-MM-yyyy" }}</p>
                                    </div>
                                    <div class="border-bottom-1 surface-border">
                                        <p class="text-900 mb-0">{{ "CONTRACT_SOURCE" | translate }}</p>
                                        <p class="text-600 mt-0">{{ currentJob.source }}</p>
                                    </div>
                                    <div class="">
                                        <p class="text-900 mb-0">{{ "REFERENCE_CODE" | translate }}</p>
                                        <p class="text-600 mb-0 mt-0">{{ (currentJob.referenceCode ? currentJob.referenceCode : currentJob.referenceCodeClient) }}</p>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>

                        </div>
                    </div>


                    <ng-template #emptySearch>
                        <div class="py-16 justify-content-center w-full hidden md:flex">
                            <div>
                                <app-empty-search [searchEmpty]="jobResponse !== null && jobResponse.total === 0"
                                                  class="block text-center"></app-empty-search>

                                <h2 class="mb-3"
                                    *ngIf="jobResponse !== null && jobResponse.total === 0; else startYourSearch">
                                    <h2 class="mb-3">{{ "NO_RESULTS_FOUND" | translate }}</h2>
                                </h2>
                                <ng-template #startYourSearch>
                                    <h2 class="mb-3">{{ "START_YOUR_SEARCH" | translate }}</h2>
                                </ng-template>

                                <form class="flex mr-3 mb-5" (ngSubmit)="filter()">
                                    <span class="p-input-icon-left w-full mr-3">
                                        <i class="pi pi-search"></i>
                                        <input [placeholder]="'SEARCH_PLACEHOLDER' | translate" type="text" pInputText
                                               [ngModelOptions]="{standalone: true}"
                                               [(ngModel)]="query"
                                               class="py-2.5 text-base rounded w-full">
                                    </span>

                                    <button pButton pRipple
                                            class="font-bold no-underline rounded-3xl px-5 shrink-0">
                                        {{ "FIND_ASSIGNMENTS" | translate }}
                                    </button>
                                </form>

                                <div class="flex">
                                    <div class="flex flex-column justify-content-between">
                                        <div class="flex-1 flex align-items-center mb-3">{{ "HOURS_PER_WEEK" | translate }}</div>
                                    </div>

                                    <div>
                                        <div class="flex mb-3 align-items-center pl-3 flex-wrap">
                                            <span class="border-2 rounded-full border-primary text-primary py-2.5 px-3.5 mr-2 mb-2 lg:mb-0 cursor-pointer"
                                                  [ngClass]="{'bg-primary': selectedHour.length === 0}"
                                                  (click)="selectedHour = []; filter();">{{ "NO_PREFERENCE" | translate }}</span>
                                            <span class="border-2 rounded-full border-primary text-primary py-2.5 px-3.5 mr-2 mb-2 lg:mb-0 cursor-pointer"
                                                  [ngClass]="{'bg-primary': selectedHour.length > 0 && selectedHour[0] === '24'}"
                                                  (click)="selectedHour = ['24']; filter();">24u</span>
                                            <span class="border-2 rounded-full border-primary text-primary py-2.5 px-3.5 mr-2 mb-2 lg:mb-0 cursor-pointer"
                                                  [ngClass]="{'bg-primary': selectedHour.length > 0 && selectedHour[0] === '36'}"
                                                  (click)="selectedHour = ['36']; filter();">36u</span>
                                            <span class="border-2 rounded-full border-primary text-primary py-2.5 px-3.5 mb-2 lg:mb-0 cursor-pointer"
                                                  [ngClass]="{'bg-primary': selectedHour.length > 0 && selectedHour[0] === '40'}"
                                                  (click)="selectedHour = ['40']; filter();">40u</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>

    <app-footer *ngIf="footer" [menu]="footer.blocks"></app-footer>
</ng-container>
