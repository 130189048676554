<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-0 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">

            <div>
                <div class="md:flex justify-content-center align-items-center">
                    <div class="max-w-[310px]">
                        <app-content *ngIf="firstContentBlock as contentBlock" [block]="contentBlock"
                                     [theme]="theme"></app-content>
                    </div>

                    <div class="flex">
                        <div class="md:ml-12 mr-4 h-26 w-26 rounded-full border-1 surface-border shrink-0 bg-cover"
                             [style]="'background: url(' + (block.asset_webp.url ? block.asset_webp.url : block.asset.url) + ')'">
                        </div>

                        <div class="">
                            <div class="mb-1 font-bold">{{ block.title }}</div>
                            <div class="mb-4">{{ block.subtitle }}</div>
                            <div class="block lg:flex">
                                <app-button *ngFor="let button of buttons; let last = last" [block]="button"
                                            [blockClass]="'!block ' + (!last ? 'mr-2' : '') + (buttons.length > 1 ? ' mb-2' : '')"></app-button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
