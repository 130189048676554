<footer class="surface-card text-white">
    <div class="container m-auto py-7 px-3 md:px-6 lg:px-8 xl:!px-42 relative overflow-hidden">
        <div class="grid">
            <div *ngFor="let menuItem of menu" class="col-12 lg:col-3 xl:col-2">
                <ul class="text-base text-white list-none p-0 m-0">
                    <li>
                        <p class="m-0 font-bold text-white no-underline">{{ menuItem.title }}</p>

                        <ul class="list-none p-0 m-0 mt-5" *ngIf="menuItem.blocks.length > 0">
                            <li *ngFor="let subMenuItem of menuItem.blocks; let last = last" [ngClass]="{'mb-3': !last}"
                                class="flex align-items-center">
                                <app-icon [icon]="subMenuItem.icon.value"
                                          class="text-primary-400 mr-2 text-2xl"></app-icon>
                                <a [href]="subMenuItem.entry ? subMenuItem.entry : (subMenuItem.url ? subMenuItem.url : '')"
                                   class="font-normal no-underline !underline-offset-4 !decoration-2 !decoration-brand-color hover:underline text-white">{{ subMenuItem.title }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex align-items-center justify-content-between mt-7 text-white">
            <div class="text-xs">&#64; {{ year }} Striive</div>

            <div class="flex">
                <a href="https://www.linkedin.com/company/striive-com/" target="_blank"
                   style="height: 2.5rem; width: 2.5rem"
                   aria-label="LinkedIn"
                   class="no-underline text-white border-circle border-primary border-1 mr-3 flex justify-content-center align-items-center">
                    <i class="pi pi-linkedin" style="font-size: 1rem"></i>
                </a>
                <a href="https://www.instagram.com/striive_com/" target="_blank" style="height: 2.5rem; width: 2.5rem"
                   aria-label="Instagram"
                   class="no-underline text-white border-circle border-primary border-1 mr-3 flex justify-content-center align-items-center">
                    <i class="pi pi-instagram" style="font-size: 1rem"></i>
                </a>
                <a href="https://www.youtube.com/@Striive_com" target="_blank" style="height: 2.5rem; width: 2.5rem"
                   aria-label="Youtube"
                   class="no-underline text-white border-circle border-primary border-1 mr-3 flex justify-content-center align-items-center">
                    <i class="pi pi-youtube" style="font-size: 1rem"></i>
                </a>
            </div>
        </div>
    </div>
</footer>
