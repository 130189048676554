<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">
            <div class="flex align-items-center mb-5">
                <div class="">
                    <div *ngIf="block.title"
                         [class]="'font-bold text-3xl'"
                         [innerHTML]="block.title | safeHTML"></div>
                </div>
                <div *ngIf="block.blocks.length > 1" class="ml-5">
                    <ul class="surface-0 p-2 m-0 list-none flex column-gap-2 overflow-x-auto select-none surface-border"
                        style="border-radius: 35px">
                        <li *ngFor="let productType of block.blocks; let index = index">
                            <button pRipple
                                    class="p-link cursor-pointer px-4 py-3 flex align-items-center transition-color transition-duration-150"
                                    [ngClass]="{
                                        'bg-primary': selectedProductType == index,
                                        'text-600': selectedProductType != index,
                                        'hover:surface-hover': selectedProductType != index}"
                                    (click)="selectedProductType = index" style="border-radius: 30px">
                                <span class="font-medium">{{ productType.title }}</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="">
                <ng-container *ngFor="let productType of block.blocks; let index = index">
                    <div *ngIf="index == selectedProductType" class="rounded-3xl overflow-x-auto">
                        <table class="w-full white-space-nowrap" style="border-collapse: collapse; border-spacing: 0">
                            <thead>
                            <tr class="bg-brand-lightest">
                                <td class="p-3">
                                </td>
                                <td *ngFor="let product of productType.blocks" class="p-3 text-center">
                                    <app-pricing-table-header [block]="product" [theme]="theme">
                                    </app-pricing-table-header>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let feature of productType.blocks[0].blocks; let index = index">
                                <tr *ngIf="index > 0" [class]="{'bg-brand-lightest': (index%2) == 0}">
                                    <td class="p-3 text-900 font-medium border-200">{{ feature.title }}</td>
                                    <ng-container *ngFor="let productFeature of productType.blocks; let findex = index">
                                        <td *ngIf="productType.blocks[findex].blocks[index].content_limited"
                                            class="text-center p-medium border-200"
                                            [innerHTML]="productType.blocks[findex].blocks[index].content_limited | safeHTML"></td>
                                        <td *ngIf="!productType.blocks[findex].blocks[index].content_limited && productType.blocks[findex].blocks[index].icon?.value != 'noIcon'"
                                            class="text-center p-medium border-200">
                                            <app-icon [icon]="productType.blocks[findex].blocks[index].icon?.value"
                                                      class="text-primary-darker text-base"></app-icon>
                                        </td>
                                        <td *ngIf="!productType.blocks[findex].blocks[index].content_limited
                                                    && productType.blocks[findex].blocks[index].icon?.value == 'noIcon'"
                                            class="text-center p-medium border-200"></td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
