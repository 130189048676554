<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div *ngIf="team" class="grid">
                <div *ngFor="let department of team.blocks" class="col-12 md:col-6 lg:col-3">
                    <div class="flex align-items-start md:align-items-center flex-column">
                        <div class="font-medium text-2xl mt-5 mb-3">{{ department.title }}</div>
                        <p-avatarGroup>
                            <p-avatar *ngFor="let employee of department.blocks" [image]="employee.asset?.url"
                                      shape="circle"
                                      size="xlarge"></p-avatar>
                        </p-avatarGroup>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
