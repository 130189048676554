<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">

    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme === 'dark'}">
            <app-text *ngIf="customBlock" [block]="customBlock" [theme]="theme"></app-text>

            <div class="grid">
                <div *ngFor="let blog of blogItems" class="col-12 lg:col-6 xl:col-4">
                    <app-blog-grid-item [block]="blog"></app-blog-grid-item>
                </div>
            </div>

            <div class="flex justify-content-center">
                <app-button *ngIf="firstButtonBlock" [theme]="theme" [block]="firstButtonBlock" class=""></app-button>
            </div>
        </div>
    </div>
</div>
